import icon from './icon.png';
import './App.css';
import {useEffect, useState} from "react";

import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago';

import en from 'javascript-time-ago/locale/en'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faClipboard, faDownload, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

TimeAgo.addDefaultLocale(en)

function App() {
  const [post, setPost] = useState({});
  const [postURL, setPostURL] = useState('');
  const [showTags, setShowTags] = useState(false);

  const getPost = async () => {
    const response = await fetch('/api/random');
    return await response.json();
  }
  useEffect(() => {
    const getPosts = async () => {
      const postsResp = await getPost();
      setPost(postsResp);
    };

    getPosts();
  }, []);

  useEffect(() => {
    //   Convert MD5 hash to e621 URL
    if (post?.md5) {
      setPostURL(`https://static1.e621.net/data/${post.md5.slice(0, 2)}/${post.md5.slice(2, 4)}/${post.md5}.${post.file_ext}`);
    }
  }, [post]);

  const handleNext = async () => {
    setPost({});
    setPostURL('');
    const postsResp = await getPost();
    setPost(postsResp);
  }

  const copyToClipboard = () => {
    fetch(postURL)
      .then(async response => {
        const blob = await response.blob()
        await navigator.clipboard.write([new ClipboardItem({[`web ${blob.type}`]: blob})])
      })
  }

  const downloadImage = () => {
    fetch(postURL)
      .then(async response => {
        const contentType = response.headers.get('content-type')
        const blob = await response.blob()
        const file = new File([blob], `e621-${post.id}.${post.file_ext}`,
          {contentType})
        // access file here
        const url = URL.createObjectURL(file)
        const a = document.createElement('a')
        a.href = url
        a.download = `e621-${post.id}.${post.file_ext}`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
  }

  return (
    <div className="App">
      <header className="App-header">
        {post && postURL ? (
          <>
            {post.file_ext in ['webm', 'mp4'] ? (
              <video controls>
                <source src={postURL} type={`video/${post.file_ext}`}/>
              </video>
            ) : (
              <img
                src={postURL}
                alt="Image"
                style={{maxHeight: '50vh', maxWidth: '50vw'}}
              />
            )}
            <p style={{color: "white"}}>
              <a href={`https://e621.net/posts/${post.id}`}>Original Post</a>{" | "}
              <a href={`https://e621.net/users/${post.uploader_id}`}>Uploader</a><br/>
              <span>
                Uploaded <ReactTimeAgo date={new Date(post.created_at)} locale="en-US"/>
              </span><br/>
              <button onClick={copyToClipboard}>
                Copy Image <FontAwesomeIcon icon={faClipboard}/>
              </button>
              {" "}
              <button onClick={downloadImage}>
                Download Image <FontAwesomeIcon icon={faDownload}/>
              </button>
              {" "}
              <button onClick={handleNext}>
                Next <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            </p>
            <button onClick={() => setShowTags(!showTags)}>
              {showTags ? "Hide" : "Show"} Tags <FontAwesomeIcon icon={showTags ? faEyeSlash : faEye}/>{' '}
            </button>
            {showTags && (
              <p>{post.tag_string}</p>
            )}
          </>
        ) : (
          <>
            <img src={icon} className="App-logo" alt="logo"/>
            <h1>Loading...</h1>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
